"use client"
import React, { useEffect, useState } from 'react';
import { SessionProvider, signIn, signOut, useSession } from "next-auth/react";


import { authContext } from './auth-context';

interface AuthProviderProps {
    children: React.ReactNode;
}


const Provider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const session = useSession();

    const login = async (creds?: any) => {
        try {
            const user = await signIn("credentials", creds)
            return user
        } catch (error) {
            console.warn(error)
        }
    };

    const logout = async () => {
        await signOut()
    };

    useEffect(() => {
        if (session.status === "authenticated") {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [session]);

    return (
        <authContext.Provider value={{ user: session.data?.user, isAuthenticated, login, logout }}>
            {children}
        </authContext.Provider>
    );
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    return (
        <SessionProvider>
            <Provider>{children}</Provider>
        </SessionProvider>
    );
};

export default AuthProvider;
