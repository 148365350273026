"use client"
import { v4 as uuidv4 } from 'uuid';
import { multiply, add, subtract } from "mathjs"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Preference } from "mercadopago";
import CartContext, { CartItem, DiscountItem } from "./context";
import { PreferenceItem } from "../mercado-pago/api/preference-response";

interface CartProviderProps {
    children: ReactNode
    mercadopago?: {
        public_key: string
    }
}

export const CartProvider: React.FC<CartProviderProps> = ({ children, mercadopago }) => {
    const [loading, setLoading] = useState(false)
    const [prefId, setPrefId] = useState<string | null>(null);
    const [cart, setCart] = useState<CartItem[]>([]);
    const [discounts, setDiscounts] = useState<DiscountItem[]>([]);
    const [client, setClient] = useState<any>(null);
    const [shipping, setShipping] = useState<{ cost: number, address: string } | null>(null);

    const addToCart = (order: CartItem) => {
        if (!order.quantity || order.quantity < 1) return console.log('Invalid quantity of article');
        if (!order.product) return console.log('Invalid product');
        order.pref = {
            id: order?.product?.id,
            title: order?.product?.name,
            description: order?.product?.description,
            quantity: order.quantity,
            unit_price: order?.product.price,
            currency_id: 'ARS',
            category_id: ""
        }
        const idx = itemExists(order)
        if (!idx) {
            setCart((prev) => [...prev, order])
            console.log("Article added to cart", order)
        }
    }

    const subtotal = useMemo(() => cart.reduce((acc, item) => add(acc, multiply(item.quantity, item.pref?.unit_price ?? 0)), 0), [cart])

    const totaldiscount = useMemo(() => discounts.reduce((acc, item) => add(acc, item.amount), 0), [discounts])

    const canCheckout = useMemo(() => {
        let cancheckout = cart.length > 0 && subtotal > 0
        if (!client) cancheckout = false
        if (!shipping) cancheckout = false
        return cancheckout
    }, [shipping, client, cart, totaldiscount, subtotal])

    const updateOrder = (order: CartItem) => { }

    const removeFromCart = (order: CartItem) => {
        const idx = findIndex(order)
        if (idx > -1) {
            setCart((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1)])
        }
    }

    const findIndex = (order: CartItem) => cart.findIndex(item => item?.product?.id === order?.product?.id)

    const itemExists = (order: CartItem) => findIndex(order) > -1;

    const checkoutCart = useCallback(async (callback = () => { }) => {
        try {
            if (prefId) {
                callback && callback()
                return
            }

            setLoading(true)
            const item: PreferenceItem = {
                id: uuidv4(),
                category_id: "",
                currency_id: "ARS",
                description: "",
                title: "Compras Crayon Ropa de Niño",
                quantity: 1,
                unit_price: 0
            }

            const total = subtract(subtotal, totaldiscount)
            item.unit_price = total

            const pref = await fetch('/api/mercado-pago', {
                method: "POST",
                body: JSON.stringify({ items: [item] }),
                headers: { 'Content-Type': 'application/json' }
            })
            if (!pref.ok) throw new Error('Error al crear la preferencia')
            const { id } = await pref.json()
            setPrefId(id)

            callback && callback()

        } catch (error) {
            console.warn(error)
        } finally {
            setLoading(false)
        }
    }, [subtotal, cart, discounts])

    const persistCart = useCallback(async (callback = () => { }) => {
        try {
            localStorage.setItem('cart', JSON.stringify(cart))
            localStorage.setItem('discounts', JSON.stringify(discounts))
            if (callback) callback()
        } catch (error) {
            console.warn(error)
        }
    }, [subtotal, cart, discounts])

    const persistClient = async (data: any, endpoint: string) => {
        try {
            let user = (await (await fetch(`${endpoint}?filters=[email:${data?.email}]`)).json())?.data?.at(0)
            if (!user) user = (await (await fetch(`${endpoint}`, {
                method: "POST",
                body: JSON.stringify({ ...data, password: data?.doc }),
                headers: { 'Content-Type': 'application/json' }
            })).json()).data
            setClient(user)
        } catch (error) {
            console.warn(error)
        }
    }

    const persistShipping = async (data: any, endpoint: string) => {
        try {
            let address = (await (await fetch(`${endpoint}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' }
            })).json()).data
            let cost = address.group ? 0 : 1000
            setShipping({ cost, address })
        } catch (error) {
            console.warn(error)
        }
    }

    const clearCart = () => setCart([])

    const addDiscount = (item: DiscountItem) => {
        const idx = discounts.findIndex(discount => discount.id === item.id)
        if (idx === -1) {
            setDiscounts((prev) => [...prev, item])
        }
    }

    const fprice = (price: number) => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(price)

    const hydrateCart = useCallback(async () => {
        try {
            let cart = localStorage.getItem('cart')
            let discounts = localStorage.getItem('discounts')
            if (cart) setCart(JSON.parse(cart))
            if (discounts) setDiscounts(JSON.parse(discounts))
        } catch (error) {
            console.warn(error)
        }
    }, [])

    useEffect(() => { hydrateCart() }, [])

    return (
        <CartContext.Provider value={{
            id: prefId, mercadopago, cart, discounts, subtotal, totaldiscount, loading,
            shipping, client, canCheckout,
            fprice, clearCart, addDiscount, addToCart, updateOrder, persistCart,
            removeFromCart, checkoutCart, itemExists, persistClient, persistShipping
        }} >
            {children}
        </CartContext.Provider>
    )
}
